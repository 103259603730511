






























































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import BaseComponent from "@/components/base-component.vue";
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import EmptyCartUndraw from "@/components/illustrations/empty-cart-undraw.vue";
import OrderCard from "@/components/my-account/order-card/order-card.vue";
import Pagination from "@/components/my-account/pagination/pagination.vue";
import Loader from "@/components/loader.vue";
import { OrdersState } from "@/store/modules/orders";
import { InvoicesState } from "@/store/modules/invoices";
import { SearchState } from "@/store/modules/search";
import Common from "@/plugins/common";

const { v4: uuidv4 } = require("uuid");

@Component({
  name: "Orders",
  components: {
    BaseIllustration,
    EmptyCartUndraw,
    OrderCard,
    Pagination,
    Loader,
  }
})
export default class Orders extends BaseComponent {
  
  private readonly ordersState: OrdersState = getModule(OrdersState, this.$store);
  private readonly invoicesState: InvoicesState = getModule(InvoicesState, this.$store);
  private readonly searchState: SearchState = getModule(SearchState, this.$store);

  items: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 5;
  showItemsOffsetForMore: number = 0;
  totalOrders: number = 0;
  usingPagination: boolean = true;
  loading: boolean = true;
  isRedirecting: boolean = false;

  thumbnails: any[] = [];

  get orders() {
    return this.ordersState.orders;
  }

  get responseStatus() {
    return this.ordersState.responseStatus;
  }

  get totalPages() {
    return Math.ceil(this.totalOrders / this.itemsPerPage);
  }

  get showItemsOffset() {
    return (this.currentPage - 1) * this.itemsPerPage;
  }

  async onChangePage(page: number) {
    if (this.currentPage === page) return;
    this.currentPage = page;
    this.showItemsOffsetForMore = 0;
    this.usingPagination = true;

    this.loading = true;
    await this.loadOrders(this.showItemsOffset, this.itemsPerPage);
  }

  async onMore() {
    let showItemsOffsetForMore = this.showItemsOffsetForMore;
    if (this.usingPagination && this.currentPage > 1) {
      if (showItemsOffsetForMore === 0) {
        showItemsOffsetForMore = this.showItemsOffset;
      }
    }
    showItemsOffsetForMore += this.itemsPerPage;
    this.showItemsOffsetForMore = showItemsOffsetForMore;
    this.currentPage = Math.ceil((showItemsOffsetForMore + this.itemsPerPage) / this.itemsPerPage);
    this.usingPagination = false;
    
    this.loading = true;
    await this.loadOrders(this.showItemsOffsetForMore, this.itemsPerPage);
  }

  async onChooseEvent() {
    this.$store.state.history = [];
    await this.$router.push({ 
      name: "home",
      params: { 
        startSearch: "true",
      }
    });
  }

  async onPay(item: any): Promise<void> {
    if (!item) return;
    if (this.isRedirecting) return;

    this.isRedirecting = true;
    
    await this.ordersState.getOrder({ orderId: item.orderId });
    if (this.responseStatus === 401) {
      this.$emit('need-auth');
      return;
    }
    
    const invoiceId: string = this.ordersState.invoiceId;
    if (invoiceId.length == 0) {
      this.isRedirecting = false;
      return;
    }

    await this.invoicesState.getPayments({ invoiceId });
    let paymentTypeId: string = 'CreditCard';
    if (this.invoicesState.payments.length > 0) {
      paymentTypeId = this.invoicesState.payments[this.invoicesState.payments.length - 1].paymentTypeId;
    }

    const payload: any = {
      invoiceId: invoiceId,
      payment: {
        paymentId: uuidv4(),
        paymentTypeId: paymentTypeId,
        redirectUrl: `${window.location.origin}/my-account/orders`,
      },
    };
    await this.invoicesState.createPayment(payload);

    const url: string = this.invoicesState.payUrl;
    if (url.length == 0) {
      this.isRedirecting = false;
      return;
    }

    window.location.href = url;
  }

  onOpenOrder(item: any) {
    if (!item) return;
    this.$router.push({ name: 'my-order', params: { id: item.orderId } });
  }  

  async loadOrders(offset: number, count: number) {
    await this.ordersState.getOrders({
      query: { offset, count },
    });
    if (this.responseStatus === 401) {
      this.$emit('need-auth');
      return;
    }
    
    const items: any[] = this.orders ? this.orders.values : [];
    this.totalOrders = this.orders ? this.orders.total : 0;
    if (this.usingPagination) {
      this.items = await this.getConvertedItems(items);
      this.loading = false;
      return;
    }
    const converted: any[] = await this.getConvertedItems(items);
    this.items.push(...converted);
    this.loading = false;
  }

  async getConvertedItems(items: any[]) {
    const orders: any[] = [];
    
    for (let i = 0; i < items.length; i += 1) {
      await this.ordersState.getItems({ orderId: items[i].orderId });

      const item: any = {
        orderId: items[i].orderId,
        date: items[i].createdOn,
        state: items[i].state,
        amount: items[i].totalAmount,
        currency: items[i].currencyId,
        paymentType: '',
        number: items[i].number,
        searches: await this.getGroupedByEvent(this.ordersState.items),
      }

      orders.push(item);
    }

    return orders;
  }

  async getGroupedByEvent(items: any[]): Promise<any[]> {
    const grouped: any[] = [];

    for (let i = 0; i < items.length; i += 1) {
      const searches: any = await this.getSearchValues(items[i]);

      if (grouped.length === 0) {
        grouped.push({ catalog: items[i].catalog, searches: [searches] });
        continue;
      }

      const foundIndex: number = this.getCatalogIndex(grouped, items[i].catalog.catalogId);
      if (foundIndex >= 0) {
        if (!this.hasSearchElement(grouped[foundIndex].searches, searches)) {
          grouped[foundIndex].searches.push(searches);
        }
        continue;
      }

      grouped.push({ catalog: items[i].catalog, searches: [searches] });
    }

    return grouped;
  }

  async getSearchValues(item: any): Promise<any> {
    const values: any = {};
    const byFace: string = Common.getAttribute(item.additionalAttributes, "byFace");
    if (byFace === 'false') {
      values.startNumber = Common.getAttribute(item.additionalAttributes, "runnerNum");
    } else if (byFace === 'true') {
      const selfieId: string = Common.getAttribute(item.additionalAttributes, "selfieId");
      const personId: string = Common.getAttribute(item.additionalAttributes, "personId");
      values.faceUrl = await this.getFaceThumbnail(selfieId, personId);
    } else {
      values.promocode = 'true';
    }
    
    return values;
  }

  async getFaceThumbnail(selfieId: string, personId: string): Promise<string> {
    if (selfieId.length === 0 || personId.length === 0) return "";

    const thumbnail: any = this.getThumbnailFromCache(selfieId, personId);
    if (thumbnail) return thumbnail.faceUrl;

    await this.searchState.loadBySelfieId({ selfieId: selfieId });
    
    const found: any[] = this.searchState.persons.filter((r: any) => r.personId == personId);
    if (found.length === 0) return "";
    
    this.thumbnails.push({ selfieId, personId, faceUrl: found[0].faceUrl });
    return found[0].faceUrl;
  }

  getCatalogIndex(items: any[], catalogId: string): number {
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].catalog.catalogId === catalogId) return i;
    }
    return -1;
  }

  hasSearchElement(items: any[], elem: any): boolean {
    for (let i = 0; i < items.length; i += 1) {
      if (elem.startNumber !== undefined) {
        if (items[i].startNumber !== undefined) {
          if (elem.startNumber === items[i].startNumber) return true;
        }
        continue;
      }
      if (elem.faceUrl !== undefined) {
        if (items[i].faceUrl !== undefined) {
          if (elem.faceUrl === items[i].faceUrl) return true;
        }
        continue;
      }
      if (elem.promocode !== undefined) {
        if (items[i].promocode !== undefined) {
          if (elem.promocode === items[i].promocode) return true;
        }
        continue;
      }
    }
    return false;
  }

  getThumbnailFromCache(selfieId: string, personId: string) {
    const found: any[] = this.thumbnails.filter((i: any) => i.selfieId === selfieId && i.personId === personId);
    if (found.length === 0) return null;
    return found[0];
  }

  async mounted() {
    await this.loadOrders(this.showItemsOffset, this.itemsPerPage);
  }
}
