












































import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "EmptyCartUndraw",
  props: {
    fill: {
      type: String,
      default: "var(--color-primary)"
    }
  },
})
export default class EmptyCartUndraw extends Vue {
}
