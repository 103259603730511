






































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import Moment from "moment";

import BaseComponent from "@/components/base-component.vue";
import { SettingsState } from "@/store/modules/settings";
import Common from "@/plugins/common";

@Component({
  name: "OrderCard",
  props: {
    options: Object,
  },
})
export default class OrderCard extends BaseComponent {

  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  get theme() {
    return this.settingsState.theme;
  }

  get orderId() {
    return this.$props.options ? this.$props.options.orderId : null;
  }

  get date() {
    return this.$props.options ? this.$props.options.date : null;
  }

  get state() {
    return this.$props.options ? this.$props.options.state : null;
  }

  get stateClass() {
    let state = '';
    if (this.state === 'Booked') state = 'booked';
    if (this.state === 'AwaitingPayment') state = 'awaiting';
    if (this.state === 'Completed') state = 'completed';
    if (this.state === 'Canceled') state = 'canceled';
    return `order-card--${state}`;
  }

  get stateText() {
    if (this.state === 'Booked') return 'Booked';
    if (this.state === 'AwaitingPayment') return this.$t('myAccount/order/stateAwaiting').toString();
    if (this.state === 'Completed') return this.$t('myAccount/order/statePaid').toString();
    if (this.state === 'Canceled') return this.$t('myAccount/order/stateCanceled').toString();
    return '';
  }

  get amount() {
    return this.$props.options ? this.$props.options.amount : 0;
  }

  get currency() {
    return this.$props.options ? this.$props.options.currency : 643;
  }

  get number() {
    return this.$props.options ? this.$props.options.number : 0;
  }

  get paymentType() {
    return this.$props.options ? this.$props.options.paymentType : '';
  }

  get paymentTypeText() {
    if (this.paymentType === 'CreditCard') return this.$t('myAccount/order/paymentCreditCard').toString();
    return '';
  }

  get searches() {
    return this.$props.options ? this.$props.options.searches : [];
  }

  get formattedDate(): string {
    return Moment.utc(this.date).local().format("LL");
  }

  get formattedAmount(): string {
    return Common.formatAmount(this.amount, false);
  }

}
